.deloitte-theme {
    deloitteai-contact-banner deloitteai-banner-link#faq,
    deloitteai-contact-banner .content__right,
    deloitteai-contact-banner .content__separator,
    brain-global-catalog-filter-tabs {
        display: none !important;
    }
    .deloitteai__banner {
        min-height: 14.75rem !important;
    }
    brain-global-header {
        .catalog_image img {
            max-height: 17rem !important;
        }
        .catalogue__header--title {
            p {
                img {
                    display: block !important;
                    margin-bottom: 1rem;
                }
                display: block !important;
                font-size: 15px;
                font-weight: bold;
                margin-top: 1rem;
            }
        }
    }
}